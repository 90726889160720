import React from 'react';

import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import Description from '@mui/icons-material/Description';
import Create from '@mui/icons-material/Create';
import Assignment from '@mui/icons-material/Assignment';
import AccountBalance from '@mui/icons-material/AccountBalance';
import TrendingUp from '@mui/icons-material/TrendingUp';
import { FETCH_COMPANY_CTE, FETCH_COMPANY_TGR, GET_COMPANY_ERUT, FETCH_COMPANY_LEGAL_REPORT, GENERATE_CLIENT_FILE_PDF, GENERATE_CLIENT_EVALUATION_PDF } from '../graphql';
import DocumentChip from '../components/cells/DocumentsChip';
import CTEandTGRDocumentChip from '../components/cells/CTEandTGRDocumentChip';
import { GenerateContractChip } from '../components/contractGeneration';
import LegalDocumentChip from '../components/cells/LegalDocumentChip';

export const HISTORIC_DOCUMENTS = ['balance', 'eerr'];

export const DOCUMENT_MAPPER = [
  {
    Component: GenerateContractChip,
    documentType: 'generated_contract',
    icon: <Create />,
    label: 'G. Contrato',
    mutation: FETCH_COMPANY_TGR,
  },
  {
    Component: DocumentChip,
    documentType: 'contract',
    icon: <Assignment />,
    label: 'Contrato',
  },
  {
    Component: DocumentChip,
    documentType: 'balance',
    icon: <AccountBalance />,
    label: 'Balance',
  },
  {
    Component: DocumentChip,
    documentType: 'eerr',
    icon: <TrendingUp />,
    label: 'EERR',
  },
  {
    Component: DocumentChip,
    documentType: 'contactability',
    icon: <TrendingUp />,
    label: 'Contactabilidad',
  },
  {
    Component: CTEandTGRDocumentChip,
    documentType: 'tgr',
    icon: <InsertDriveFile />,
    label: 'TGR',
    mutation: FETCH_COMPANY_TGR,
  },
  {
    Component: CTEandTGRDocumentChip,
    documentType: 'cte',
    icon: <Description />,
    label: 'CTE',
    mutation: FETCH_COMPANY_CTE,
  },
  {
    Component: CTEandTGRDocumentChip,
    documentType: 'erut',
    icon: <Description />,
    label: 'E-Rut',
    mutation: GET_COMPANY_ERUT,
  },
  {
    Component: CTEandTGRDocumentChip,
    documentType: 'legal_report',
    icon: <Description />,
    label: 'Reporte legal',
    mutation: FETCH_COMPANY_LEGAL_REPORT,
  },
  {
    Component: LegalDocumentChip,
    documentType: 'client_file_uaf',
    icon: <Create />,
    label: 'Ficha de Cliente',
    mutation: GENERATE_CLIENT_FILE_PDF,
  },
  {
    Component: LegalDocumentChip,
    documentType: 'funds_back_up',
    icon: <Create />,
    label: 'Respaldo Fondos',
    mutation: GENERATE_CLIENT_EVALUATION_PDF,
  },
];
